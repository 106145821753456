import * as React from 'react'
import { useReservationDataCheck } from '@hooks/use-reservation-data-check'
import { Step } from '@components/steps/step'
import { SummaryBoxMobile } from '@modules/aside/summary-box/summary-box-mobile'
import { SummaryPromocode } from '@modules/summary-step/promocodes/summary-promocode'
import { SummaryCancellationWarranty } from '@modules/summary-step/summary-cancellation-warranty'
import { SummaryRequests } from '@modules/summary-step/summary-requests'
import { SummaryInvoice } from './invoice/summary-invoice'
import { SummaryReservationData } from '@modules/summary-step/summary-reservation-data'
import { SummaryPrices } from '@modules/summary-step/prices/summary-prices'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { useScrollToError } from '@hooks/use-form-request'
import { ReactSelectOption } from '@components/controls/custom-react-select'
import { selectCountries } from '@store/selectors/app-data-selectors'
import { Country } from '@models/app-data'
import { SummaryClientData } from '@modules/summary-step/summary-client-data'
import { SummaryGastroVoucher } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher'
import { FinishReservationRequestPayload } from '@requests/reservation/reservation-request-models'
import { selectClientDetails } from '@store/selectors/client-details-selectors'

export const summaryStepInputLayout = {
  formLabelProps: { className: 'col-md-6 col-12' },
  formControlWrapperClassName: 'col-md-6 col-12',
  formGroupProps: { className: 'd-flex align-items-center flex-md-row flex-column' },
}

export interface SummaryStepFormInputs extends Omit<FinishReservationRequestPayload, 'invoice_country' | 'country'> {
  code: string
  invoice_country: ReactSelectOption
  country: ReactSelectOption
  allRulesSelected: boolean
  allMarketingRulesSelected: boolean
}

export const SummaryStep = (): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)
  useReservationDataCheck()

  const reservation = useSelector(selectReservation)
  const countries = useSelector(selectCountries)

  const invoiceCountry = countries.find((country: Country) => country.id === reservation.invoice_country)

  const methods = useForm<SummaryStepFormInputs>({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      name: reservation.name,
      street: reservation.street,
      postcode: reservation.postcode,
      city: reservation.city,
      email: reservation.email,
      phone: reservation.phone,
      country: { value: 'PL', label: 'Polska' },
      requests: reservation.requests,
      invoice: reservation.invoice,
      invoice_city: reservation.invoice_city,
      invoice_company: reservation.invoice_company,
      invoice_country: { value: invoiceCountry?.id ?? 'PL', label: invoiceCountry?.name ?? 'Polska' },
      invoice_nip: reservation.invoice_nip,
      invoice_postcode: reservation.invoice_postcode,
      invoice_street: reservation.invoice_street,
      invoice_type: clientDetails?.profile.invoice_type ?? 'company',
      main_rules: false,
      services_rules: false,
      booking_promotion_earlier: false,
      booking_promotion_february_2025: false,
      phone_call_rules: false,
      email_rules: false,
      sms_rules: false,
      allRulesSelected: false,
      warranty: reservation.warranty,
      code: '',
    },
  })

  React.useEffect(() => {
    const getCountryForField = (field: 'invoice_country' | 'country') =>
      countries.find((country: Country) => country.id === reservation[field])

    methods.setValue('email', reservation.email)
    methods.setValue('name', reservation.name)
    methods.setValue('invoice', reservation.invoice)
    methods.setValue('invoice_city', reservation.invoice_city)
    methods.setValue('invoice_company', reservation.invoice_company)
    methods.setValue('invoice_nip', reservation.invoice_nip)
    methods.setValue('invoice_postcode', reservation.invoice_postcode)
    methods.setValue('invoice_street', reservation.invoice_street)
    methods.setValue('code', reservation.promocode ? reservation.promocode.code : '')

    const country = getCountryForField('country')
    if (country) methods.setValue('invoice_country', { label: country.name, value: country.id })

    const invoiceCountry = getCountryForField('invoice_country')
    if (invoiceCountry) methods.setValue('invoice_country', { label: invoiceCountry.name, value: invoiceCountry.id })
  }, [reservation])

  useScrollToError(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <div>
        <div className="bg-white rounded-top bg-shadow">
          <div className="pt-xl-3">
            <SummaryBoxMobile wrapperClassName="d-xl-none" />
            <Step />
          </div>
          <SummaryReservationData wrapperClassName="border-bottom" />
          <SummaryClientData />
        </div>
        <SummaryInvoice />
        <SummaryPromocode />
        <SummaryGastroVoucher />
        {reservation.can_add_warranty && <SummaryCancellationWarranty />}
        <SummaryRequests />
        <SummaryPrices />
      </div>
    </FormProvider>
  )
}
