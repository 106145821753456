import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { Rules } from '@helpers/rules'
import { ExpandableText } from '@components/expandable-text'

export const MARKETING_RULES_KEYWORDS = ['email_rules', 'sms_rules', 'phone_call_rules'] as const

export const SummaryStepMarketingConsents = (): JSX.Element => {
  const { control, setValue } = useFormContext<SummaryStepFormInputs>()

  const marketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = marketingRules.filter(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    const isEachSelected = marketingRules.every(Boolean)

    setValue('allMarketingRulesSelected', isEachSelected)
  }, [marketingRules])

  return (
    <>
      <Checkbox
        className="checkbox--small font-size-sm align-items-start"
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        label={
          <div className="mt-1">
            <div className="mb-1">Zgoda na przetwarzanie danych w celach marketingowych</div>
            <ExpandableText text={Rules.marketing_rules} initialHeight={12} />
          </div>
        }
        indeterminate={isAnyMarketingRuleSelected}
      />

      <div className="mt-2" style={{ marginLeft: 29 }}>
        <p className="text-muted font-size-sm mb-1 mt-3 col-11">{Rules.marketing_rules_communication_channel}</p>
        <Checkbox
          name="phone_call_rules"
          id="phone_call_rules"
          label={<div className="mt-1">telefonu</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />
        <div className="mt-2 mb-1 text-muted ps-2 font-size-xs">
          środków komunikacji elektronicznej, w szczególności:
        </div>
        <Checkbox
          name="email_rules"
          id="email_rules"
          label={<div className="mt-1">wiadomości email</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />
        <Checkbox
          name="sms_rules"
          id="sms_rules"
          label={<div className="mt-1">wiadomości SMS</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />
      </div>
    </>
  )
}
