import * as React from 'react'
import { isBlackWeekActive, isChristmasDiscountActive, isFebruaryDiscountActive } from '@components/discounts/utils'
import { BlackWeekBanner } from '@components/discounts/black-week/black-week-banner'
import { ChristmasDiscountBanner } from '@components/discounts/christmas-discount/christmas-discount-banner'
import { FebruaryDiscountBanner } from '@components/discounts/february-discount/february-discount-banner'

export const GeneralDiscount = (): JSX.Element => (
  <>
    {isBlackWeekActive() && <BlackWeekBanner />}
    {isChristmasDiscountActive() && <ChristmasDiscountBanner />}
    {isFebruaryDiscountActive() && <FebruaryDiscountBanner />}
  </>
)
