import * as React from 'react'
import { BaseModalProps } from '@modals/types'
import { DiscountModalBase } from '@components/discounts/discount-modal'

export const FebruaryDiscountModal = ({ toggleIsVisible }: BaseModalProps): JSX.Element => {
  console.log('asd')
  return (
    <DiscountModalBase
      textSlideLength={8}
      textSlideDelay={1}
      onClose={toggleIsVisible}
      text="PROMOCJA"
      image={require('@assets/images/february-discount-modal-bg.webp')}
    />
  )
}
