import * as React from 'react'
import { DiscountBanner } from '@components/discounts/discount-banner'

export const FebruaryDiscountBanner = (): JSX.Element => {
  return (
    <DiscountBanner
      discountType="february-discount"
      modal="FebruaryDiscountModal"
      date="od 01.02 do 16.02"
      image={require('@assets/images/black-week-discount.webp')}
      text={
        <>
          <span style={{ color: '#EED478' }}>Black Week w lutym</span>{' '}
          <span className="text-nowrap">w Holiday Park & Resort!</span>
        </>
      }
    />
  )
}
