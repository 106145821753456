import { ApartmentDetailsModal } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-details-modal'
import { ApartmentLocalizationModal } from '@modules/accommodation-selection-step/local-selection/apartment-details/apartment-localization-modal'
import { OpinionsModal } from '@modules/accommodation-selection-step/opinions/opinions-modal'
import { LocalSelectionMapModal } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-selection-map-modal'
import { DateSelectionModal } from '@modules/accommodation-selection-step/dates-selection/date-selection-modal'
import { ModalProps } from 'react-bootstrap/Modal'
import { NotificationMessageModal } from '@modals/notification-message-modal'
import { LoginModal } from '@modules/header/login/login-modal'
import { SummaryBoxMobileModal } from '@modules/aside/summary-box/summary-box-mobile-modal'
import { FeedingDetailsModal } from '@modules/improvement-step/improvements-sections/kinds/feedings/feeding-details-modal'
import { VipDetailListModal } from '@modules/improvement-step/improvements-sections/kinds/vip/vip-detail-list-modal'
import { VipDetailsModal } from '@modules/improvement-step/improvements-sections/kinds/vip/vip-details-modal'
import { ImprovementDetailsModal } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-details-modal'
import { ImprovementDeleteModal } from '@modules/improvement-step/improvements-sections/kinds/improvements/improvement-delete-modal'
import { StayOptionDetailsModal } from '@modules/improvement-step/improvements-sections/kinds/stay-options/stay-option-details-modal'
import { SummaryGastroVoucherModal } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-modal'
import { SummaryGastroVoucherRemoveModal } from '@modules/summary-step/promocodes/gastro-voucher/summary-gastro-voucher-remove-modal'
import { ReservationExpiredModal } from '@modals/reservation-expired-modal'
import { ReservationDataCleanupModal } from '@modals/reservation-data-cleanup-modal'
import { WarrantyDeleteModal } from '@modules/aside/summary-box/summary-box-warranty-delete-modal'
import { SubscriptionContractDescriptionModal } from '@modules/aside/subscription-contract-info/subscription-contract-description-modal'
import { InvalidSelectedAccommodationTypeModal } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/invalid-selected-accommodation-type-modal'
import { LocalForClientWithBenefitModal } from '@modules/accommodation-selection-step/local-selection/local-selection-map/local-for-client-with-benefit/local-for-client-with-benefit-modal'
import { InvalidCodeModal } from '@modules/summary-step/promocodes/invalid-code-modal'
import { BlackWeekModal } from '@components/discounts/black-week/black-week-modal'
import { ChristmasDiscountModal } from '@components/discounts/christmas-discount/christmas-discount-modal'
import { FebruaryDiscountModal } from '@components/discounts/february-discount/february-discount-modal'

const BaseImprovementModalClasses = 'base-improvement-modal is-medium-large-desktop'

export interface BaseModalProps {
  toggleIsVisible: () => void
}

export type ModalParams = ModalProps

type ModalComponent = (params) => JSX.Element

type ModalsMapType = {
  [key in ModalTypes]: [ModalComponent, ModalParams]
}

export type ModalTypes =
  | 'ApartmentDetailsModal'
  | 'ApartmentLocalizationModal'
  | 'DateSelectionModal'
  | 'FeedingDetailsModal'
  | 'ImprovementDeleteModal'
  | 'ImprovementDetailsModal'
  | 'InvalidSelectedAccommodationTypeModal'
  | 'InvalidCodeModal'
  | 'LocalForClientWithBenefitModal'
  | 'LocalSelectionMapModal'
  | 'LoginModal'
  | 'NotificationMessageModal'
  | 'OpinionsModal'
  | 'ReservationDataCleanupModal'
  | 'ReservationExpiredModal'
  | 'ReservationInformationModal'
  | 'StayOptionDetailsModal'
  | 'SubscriptionContractDescriptionModal'
  | 'SummaryGastroVoucherModal'
  | 'SummaryGastroVoucherRemoveModal'
  | 'VipDetailListModal'
  | 'VipDetailsModal'
  | 'WarrantyDeleteModal'
  | 'BlackWeekModal'
  | 'ChristmasDiscountModal'
  | 'FebruaryDiscountModal'

export const ModalsMap: ModalsMapType = {
  ApartmentDetailsModal: [ApartmentDetailsModal, { size: 'xl' }],
  ApartmentLocalizationModal: [ApartmentLocalizationModal, { size: 'lg' }],
  OpinionsModal: [OpinionsModal, { size: 'xl', className: 'opinions__modal' }],
  LocalSelectionMapModal: [
    LocalSelectionMapModal,
    { size: 'xl', className: 'borderless-header local-selection-modal' },
  ],
  DateSelectionModal: [DateSelectionModal, {}],
  ReservationInformationModal: [SummaryBoxMobileModal, {}],
  FeedingDetailsModal: [FeedingDetailsModal, { size: 'lg', className: BaseImprovementModalClasses }],
  VipDetailListModal: [VipDetailListModal, { size: 'lg', className: BaseImprovementModalClasses }],
  VipDetailsModal: [VipDetailsModal, { size: 'lg', className: BaseImprovementModalClasses }],
  ImprovementDetailsModal: [ImprovementDetailsModal, { size: 'lg', className: BaseImprovementModalClasses }],
  ImprovementDeleteModal: [ImprovementDeleteModal, { className: 'borderless-header' }],
  WarrantyDeleteModal: [WarrantyDeleteModal, { className: 'borderless-header' }],
  BlackWeekModal: [BlackWeekModal, { className: 'black-week-modal' }],
  FebruaryDiscountModal: [FebruaryDiscountModal, { className: 'black-week-modal' }],
  ChristmasDiscountModal: [ChristmasDiscountModal, { className: 'black-week-modal' }],
  StayOptionDetailsModal: [StayOptionDetailsModal, { className: BaseImprovementModalClasses }],
  NotificationMessageModal: [
    NotificationMessageModal,
    { centered: true, className: 'custom-modal-with-image is-notification-modal' },
  ],
  LoginModal: [LoginModal, { size: 'lg', className: 'custom-modal-with-image is-login-modal' }],
  SummaryGastroVoucherModal: [SummaryGastroVoucherModal, { className: 'modal-semi-lg' }],
  SummaryGastroVoucherRemoveModal: [SummaryGastroVoucherRemoveModal, { className: 'borderless-header' }],
  ReservationExpiredModal: [
    ReservationExpiredModal,
    {
      centered: true,
      className: 'custom-modal-with-image is-reservation-expiration-modal',
      backdrop: 'static',
      keyboard: false,
    },
  ],
  ReservationDataCleanupModal: [ReservationDataCleanupModal, {}],
  InvalidCodeModal: [
    InvalidCodeModal,
    { keyboard: false, backdrop: 'static', className: 'not-closable custom-modal-with-image' },
  ],
  SubscriptionContractDescriptionModal: [SubscriptionContractDescriptionModal, { className: ' modal-semi-lg' }],
  LocalForClientWithBenefitModal: [
    LocalForClientWithBenefitModal,
    { size: 'lg', className: 'subscription-with-benefits-owner-modal' },
  ],
  InvalidSelectedAccommodationTypeModal: [
    InvalidSelectedAccommodationTypeModal,
    { size: 'sm', className: 'borderless-header not-closable' },
  ],
}

export interface ModalConfig {
  persist?: boolean
  onClose?: () => void
  onHide?: () => void
}
