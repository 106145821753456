import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { SummaryPaymentSchedule } from '@modules/summary-step/payment/summary-payment-schedule'
import { useFormRequest } from '@hooks/use-form-request'
import { finishReservation } from '@requests/reservation/reservation-requests'
import { useDispatch, useSelector } from 'react-redux'
import { SaveButton } from '@components/controls/save-button'
import { SummaryStepFormInputs } from '../summary-step'
import { SummaryStepConsents } from '@modules/summary-step/summary-step-consents'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { setIsPageLeaveAllowed } from '@store/actions/page-leave-actions'

export const SummaryPayment = (): JSX.Element => {
  const { setError, getValues, clearErrors } = useFormContext<SummaryStepFormInputs>()

  const { handleAction, isLoading } = useFormRequest(setError, {
    axiosFormErrorConfig: { nonFieldErrorsAs: 'sms_rules' },
  })

  const dispatch = useDispatch()

  const reservation = useSelector(selectReservation)

  const handleReservationFinish = async () => {
    dispatch(setIsPageLeaveAllowed(true))
    const values = getValues()
    clearErrors()
    const { isResolved } = await handleAction(() =>
      dispatch(
        finishReservation({
          name: values.name,
          street: values.street,
          postcode: values.postcode,
          city: values.city,
          country: values.country?.value,
          email: values.email,
          phone: values.phone,
          requests: values.requests,
          main_rules: values.main_rules,
          services_rules: values.services_rules,
          phone_call_rules: values.phone_call_rules,
          animals_rules: values.animals_rules,
          booking_promotion_black_week_2024: values.booking_promotion_black_week_2024,
          booking_promotion_christmas_2024: values.booking_promotion_christmas_2024,
          booking_promotion_february_2025: values.booking_promotion_february_2025,
          booking_promotion_earlier: values.booking_promotion_earlier,
          departure_voucher_rules: values.departure_voucher_rules,
          information_clause: values.information_clause,
          email_rules: values.email_rules,
          sms_rules: values.sms_rules,
          warranty: values.warranty,
        }),
      ),
    )

    if (!isResolved || !reservation.token) return
    document.location.href = reservation.urls.sale_payment_select
  }

  return (
    <div className="container-lg px-xl-4 bg-white py-4">
      {reservation.prices.payments_summary && (
        <SummaryPaymentSchedule paymentSummary={reservation.prices.payments_summary} isPaid={reservation.is_paid} />
      )}
      <SummaryStepConsents />
      <SaveButton
        onClick={handleReservationFinish}
        className="btn btn-danger w-responsiveness-btn text-center px-5 py-3 mt-4 d-flex flex-column align-items-center mx-auto"
        iconClassName=""
        isLoading={isLoading}
        textWrapperClassName="h-100"
        text={
          <div className="lh-initial">
            <span className="fw-semi-bold d-block">Potwierdzenie rezerwacji</span>
            <strong className="font-size-xl">Rezerwuję i płacę</strong>
          </div>
        }
      />
    </div>
  )
}
