import * as React from 'react'

export const Rules = {
  animals_rules: 'https://holidaypark.pl/regulaminy/pobierz/regulamin-pobyt-zwierzat/',
  email_rules:
    'Zgodnie z art. 10 ust. 2 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (tj.. Dz. U. z 2016 r. poz. 1030) wyrażam dobrowolnie zgodę na przesyłanie na wskazany przeze mnie adres e-mail informacji handlowej przez Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J.',
  sms_rules:
    'Zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (tj. Dz. U. z 2016 r. poz. 1489) wyrażam dobrowolnie zgodę na przesyłanie na wskazany przeze mnie numer telefonu, którego jestem użytkownikiem, informacji handlowej przez Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J.',
  marketing_rules: (
    <div className="col-lg-9 col-11">
      Wyrażam zgodę na przetwarzanie przez Holiday Park & Resort sp. z o.o. sp. k. <br /> - siedziba: 63-100 Śrem, ul.
      Fryderyka Chopina 1J - w celach marketingowych następujących dotyczących mnie danych osobowych: imienia, nazwiska,
      adresu poczty elektronicznej, numeru telefonu.
    </div>
  ),
  marketing_rules_communication_channel:
    'Na podstawie ustawy z dnia 12 lipca 2024 r. Prawo komunikacji elektronicznej wyrażam zgodę na kontaktowanie się ze mną i na przesyłanie mi przez Holiday Park & Resort sp. z o.o. sp. k. informacji handlowej, w tym w celu marketingu bezpośredniego, za pośrednictwem:',
  phone_call_rules:
    'Zgoda na dostarczanie powiadomień i ofert poprzez połączenia telefoniczne. Zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (tj. Dz. U. z 2016 r. poz. 1489) wyrażam dobrowolnie zgodę na przesyłanie na wskazany przeze mnie numer telefonu, którego jestem użytkownikiem, informacji handlowej przez Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J.',
  list: 'https://holidaypark.pl/regulaminy',
}
